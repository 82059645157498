export type Experiment = 'conversations' | 'debug' | 'qa' | 'rfi';

export type Feature = 'conversations' | 'contacts' | 'debug' | 'qa' | 'rfi';

export function isFeatureAvailable( experiments: Experiment[], feature: Feature ) {
    switch ( feature ) {
        case 'conversations':
            return experiments.includes( 'conversations' ) || experiments.includes( 'rfi' );
        case 'contacts':
            return experiments.includes( 'conversations' ) || experiments.includes( 'rfi' );
        case 'debug':
            return experiments.includes( 'debug' );
        case 'qa':
            return experiments.includes( 'qa' );
        case 'rfi':
            return experiments.includes( 'rfi' );
        default:
            return false;
    }
}
